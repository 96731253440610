import React, { useState, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/flatpickr.css';
import moment from 'moment';

const ranges = {
    'Today': [moment().startOf('day').format('YYYY-MM-DD'), moment().endOf('day').format('YYYY-MM-DD')],
    'This Week': [moment().startOf('week').format('YYYY-MM-DD'), moment().endOf('week').format('YYYY-MM-DD')],
    'This Month': [moment().startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD')],
    'This Year': [moment().startOf('year').format('YYYY-MM-DD'), moment().endOf('year').format('YYYY-MM-DD')],
    'Last Week': [moment().subtract(1, 'week').startOf('week').format('YYYY-MM-DD'), moment().subtract(1, 'week').endOf('week').format('YYYY-MM-DD')],
    'Last Month': [moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'), moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD')],
    'Last Year': [moment().subtract(1, 'year').startOf('year').format('YYYY-MM-DD'), moment().subtract(1, 'year').endOf('year').format('YYYY-MM-DD')],
    'Custom Range': 'custom',
};

const TaskFilter = ({ users, filters, setFilters, resetFilters }) => {
    const [selectedRange, setSelectedRange] = useState('Today');
    const [customStart, setCustomStart] = useState(null);
    const [customEnd, setCustomEnd] = useState(null);

    useEffect(() => {
        if (selectedRange === 'Custom Range') {
            if (customStart && customEnd) {
                setFilters({
                    ...filters,
                    startDate: moment(customStart).format('YYYY-MM-DD'),
                    endDate: moment(customEnd).format('YYYY-MM-DD'),
                });
            }
        } else {
            setFilters({
                ...filters,
                startDate: ranges[selectedRange][0],
                endDate: ranges[selectedRange][1],
            });
        }
    }, [selectedRange, customStart, customEnd]);

    const handleDateChange = (dates) => {
        const [startDate, endDate] = dates;
        setCustomStart(startDate);
        setCustomEnd(endDate);
    };

    return (
        <div>
            <div className="d-flex align-items-end">
                
                <Form.Group controlId="dateRange" className="me-3">
                    <Form.Label>Period</Form.Label>
                    <Form.Select
                        value={selectedRange}
                        onChange={(e) => setSelectedRange(e.target.value)}
                    >
                        {Object.keys(ranges).map((range) => (
                            <option key={range} value={range}>
                                {range}
                            </option>
                        ))}
                    </Form.Select>
                </Form.Group>
                {selectedRange === 'Custom Range' && (
                    <div className="me-3">
                        <Flatpickr
                            className="form-control"
                            options={{
                                mode: 'range',
                                dateFormat: 'Y-m-d',
                                defaultDate: [customStart, customEnd],
                            }}
                            onChange={handleDateChange}
                            placeholder="Select Date Range"
                        />
                    </div>
                )}
                {users &&
                    <Form.Group controlId="userSelect" className="me-3">
                        <Form.Label>User</Form.Label>
                        <Form.Select
                            value={filters.userId}
                            onChange={(e) => setFilters({ ...filters, userId: e.target.value })}
                        >
                            <option value="">All Users</option>
                            {users.map((user) => (
                                <option key={user._id} value={user._id}>
                                    {user.name}
                                </option>
                            ))}
                        </Form.Select>
                    </Form.Group>}
                <Form.Group controlId="statusSelect" className="me-3">
                    <Form.Label>Status</Form.Label>
                    <Form.Select
                        value={filters.status}
                        onChange={(e) => setFilters({ ...filters, status: e.target.value })}
                    >
                        <option value="">Any</option>
                        <option value="Pending">Pending</option>
                        <option value="Pending Approval">Pending Approval</option>
                        <option value="Approved">Approved</option>
                        <option value="Completed">Completed</option>
                        <option value="Rejected">Rejected</option>
                    </Form.Select>
                </Form.Group>
                <Button variant="secondary" onClick={resetFilters}>
                    Reset
                </Button>
            </div>
        </div>
    );
};

export default TaskFilter;
