import React, { useEffect, useState } from 'react';
import { Button, Modal, Form, Alert } from 'react-bootstrap';
import moment from 'moment';
import 'flatpickr/dist/flatpickr.css';
import TaskFilter from './Filter';
import BASE_URL from '../../config';
import toast from 'react-hot-toast'

const AllTasks = () => {
    const [users, setUsers] = useState([]);
    const [tasks, setTasks] = useState([]);
    const [selectedTask, setSelectedTask] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [showCreateTaskForm, setShowCreateTaskForm] = useState(false);
    const [filters, setFilters] = useState({
        startDate: moment().startOf('day').format('YYYY-MM-DD'),
        endDate: moment().endOf('day').format('YYYY-MM-DD'),
        userId: '',
        status: ''
    });
    const [newTask, setNewTask] = useState({
        taskName: '',
        assignedTo: ''
    });
    useEffect(() => {
        fetchData();
        fetchUserTasks();
    }, [filters]);

    const fetchData = async () => {
        try {
            const res = await fetch(`${BASE_URL}/getallusers`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json"
                }
            });
            const resultdata = await res.json();
            setUsers(resultdata.allusers);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const fetchUserTasks = async () => {
        try {
            const res = await fetch(`${BASE_URL}/alltasks`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(filters)
            });
            const resultdata = await res.json();
            setTasks(resultdata.allTasks);
        } catch (error) {
            console.error('Error fetching tasks:', error);
        }
    };

    const resetFilters = () => {
        setFilters({
            startDate: moment().startOf('day').format('YYYY-MM-DD'),
            endDate: moment().endOf('day').format('YYYY-MM-DD'),
            userId: '',
            status: ''
        });
        fetchUserTasks();
    };

    const handleTaskClick = (task) => {
        setSelectedTask(task);
        setShowModal(true);
    };

    const handleApprove = async () => {
        await updateTaskStatus('Approved');
    };

    const handleReject = async () => {
        await updateTaskStatus('Rejected');
    };

    const updateTaskStatus = async (status) => {
        if (!selectedTask) return;

        try {
            const res = await fetch(`${BASE_URL}/tasks/${selectedTask._id}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ status })
            });

            if (res.ok) {
                const updatedData = await res.json();
                setTasks(tasks.map(task => task._id === updatedData.task._id ? updatedData.task : task));
                toast.success("Task status updated successfully");
                setShowModal(false);
            } else {
                console.error('Failed to update task status');
            }
        } catch (error) {
            console.error('Error updating task status:', error);
        }
    };

    const handleCreateTask = async () => {
        try {
            const res = await fetch(`${BASE_URL}/tasks`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ ...newTask, status: 'Pending' })
            });

            if (res.ok) {
                const result = await res.json();
                toast.success('Task created successfully')
                setTasks([result.task, ...tasks]);
                setShowCreateTaskForm(false);
                setNewTask({ taskName: '', assignedTo: '' });
            } else {
                console.error('Failed to create task');
            }
        } catch (error) {
            console.error('Error creating task:', error);
        }
    };

    const getStatusBgColor = (status) => {
        switch (status) {
            case 'Approved':
                return 'bg-success';
            case 'Rejected':
                return 'bg-danger';
            case 'Pending Approval':
                return 'bg-white text-danger';
            case 'Pending':
                return 'bg-warning';
            default:
                return 'bg-secondary';
        }
    };

    return (
        <div>
            <div className="d-flex justify-content-between align-items-center mb-3">
                <TaskFilter
                    users={users}
                    filters={filters}
                    setFilters={setFilters}
                    resetFilters={resetFilters}
                />
                <Button variant="success" onClick={() => setShowCreateTaskForm(!showCreateTaskForm)}>
                    {showCreateTaskForm ? 'Cancel' : 'Create Task'}
                </Button>
            </div>

            {showCreateTaskForm && (
                <div className="mb-4 p-3 bg-light rounded">
                    <Form.Group controlId="taskName" className="mb-3">
                        <Form.Label>Task Name</Form.Label>
                        <Form.Control
                            type="text"
                            value={newTask.taskName}
                            onChange={(e) => setNewTask({ ...newTask, taskName: e.target.value })}
                        />
                    </Form.Group>
                    <Form.Group controlId="assignedTo" className="mb-3">
                        <Form.Label>Assign to</Form.Label>
                        <Form.Control
                            as="select"
                            value={newTask.assignedTo}
                            onChange={(e) => setNewTask({ ...newTask, assignedTo: e.target.value })}
                        >
                            <option value="">Select User</option>
                            {users.map(user => (
                                <option key={user._id} value={user._id}>
                                    {user.name}
                                </option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                    <Button variant="primary" onClick={handleCreateTask}>
                        Save
                    </Button>
                </div>
            )}

            <div className="row">
                {tasks.length > 0 ? (
                    tasks?.map((task) => (
                        <div key={task._id} className="col-md-4 mb-3">
                            <div
                                className='card h-100 shadow-sm'
                                style={{ backgroundColor: '#e7f3ff', borderColor: task?.status === 'Pending Approval' ? '#ffc107' : '' }}
                            >
                                <div className="card-body">
                                    <h5 className="card-title">{task.taskName}</h5>
                                    <p className="card-text"><strong>Status:</strong> <span className={`badge ${getStatusBgColor(task.status)}`}>{task.status}</span></p>
                                    <p className="card-text"><strong>Comment:</strong> {task.comment || 'N/A'}</p>
                                    <p className="card-text"><strong>Created:</strong> {moment(task.createdAt).format('LLL')}</p>
                                    <Button variant="primary" onClick={() => handleTaskClick(task)}>View Details</Button>
                                </div>
                            </div>
                        </div>
                    ))
                ) : (
                    <p>No tasks available</p>
                )}
            </div>

            <Modal show={showModal} onHide={() => setShowModal(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Task Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedTask && (
                        <div>
                            <p><strong>Task Name:</strong> {selectedTask.taskName}</p>
                            <p><strong>Assigned to:</strong> {selectedTask.assignedTo.name}</p>
                            <p><strong>Status:</strong> <span className={`badge ${getStatusBgColor(selectedTask.status)}`}>{selectedTask.status}</span></p>
                            <p><strong>Comment:</strong> {selectedTask.comment || 'N/A'}</p>
                            <p><strong>Location:</strong> {selectedTask.location || 'N/A'}</p>
                            <p><strong>Created:</strong> {moment(selectedTask.createdAt).format('LLL')}</p>
                            <p><strong>Submitted:</strong> {selectedTask.status === "Completed" ? moment(selectedTask.updatedAt).format('LLL') : "Not yet submitted"}</p>
                            {selectedTask.picture && (
                                <div>
                                    <strong>Picture:</strong>
                                    <img src={selectedTask.picture} alt={selectedTask.picture} className="img-fluid" />
                                </div>
                            )}

                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    {selectedTask?.status === 'Pending Approval' && (
                        <div className="d-flex justify-content-end">
                            <Button variant="success" onClick={handleApprove} className="me-2">Approve</Button>
                            <Button variant="danger" onClick={handleReject}>Reject</Button>
                        </div>
                    )}
                    <Button variant="secondary" onClick={() => setShowModal(false)}>Close</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default AllTasks;
