import React, { useEffect, useState } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import BASE_URL from '../../config';
import moment from 'moment';
import TaskFilter from '../Manager/Filter';

const MyTasks = () => {
    const [tasks, setTasks] = useState([]);
    const [selectedTask, setSelectedTask] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [formData, setFormData] = useState({
        picture: '',
        location: '',
        comment: '',
        status: 'Completed'
    });
    const [useAutomaticLocation, setUseAutomaticLocation] = useState(false);
    const userId = localStorage.getItem('userid');

    const [filters, setFilters] = useState({
        startDate: moment().startOf('day').format('YYYY-MM-DD'),
        endDate: moment().endOf('day').format('YYYY-MM-DD'),
        userId: userId,
        status: ''
    });
    

    useEffect(() => {
        fetchMyTasks();
    }, [filters]);

    const fetchMyTasks= async ()=> {
        try {
            const res = await fetch(`${BASE_URL}/alltasks`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(filters)
            });
            const resultdata = await res.json();
            setTasks(resultdata.allTasks);
        } catch (error) {
            console.error('Error fetching tasks:', error);
        }
    }
    const handleTaskClick = (task) => {
        setSelectedTask(task);
        setFormData({
            picture: task.picture || '',
            location: task.location || '',
            comment: task.comment || '',
            status: task.status === 'Completed' ? 'Completed' : 'Pending'
        });
        setOpenModal(true);
    };

    const handleInputChange = (name, value) => {
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    const resetFilters = () => {
        setFilters({
            startDate: moment().startOf('day').format('YYYY-MM-DD'),
            endDate: moment().endOf('day').format('YYYY-MM-DD'),
            userId: userId,
            status: ''
        });
        fetchMyTasks();
    };

    const handleFileChange = async (files) => {
        const imageFormData = new FormData();
        imageFormData.append('file', files[0]);

        try {
            const response = await fetch(`${BASE_URL}/saveimage`, {
                method: 'POST',
                body: imageFormData
            });

            if (response.ok) {
                const filePath = await response.json();
                handleInputChange('picture', filePath.filePath);
            } else {
                console.error('Error:', response.statusText);
            }
        } catch (error) {
            console.error('Error uploading file:', error);
        }
    };

    const handleLocationChange = async () => {
        if (useAutomaticLocation && navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                async (position) => {
                    const { latitude, longitude } = position.coords;
                    try {
                        const response = await fetch(`https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json`);
                        const data = await response.json();
                        handleInputChange('location', data.display_name);
                    } catch (error) {
                        console.error('Error fetching location:', error);
                    }
                },
                (error) => {
                    console.error('Error getting location:', error);
                }
            );
        }
    };

    const handleSubmit = async () => {
        if (!selectedTask) return;

        setFormData((prevData) => ({
            ...prevData,
            status: 'Completed'
        }));

        try {
            const res = await fetch(`${BASE_URL}/tasks/${selectedTask._id}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ ...formData, status: 'Completed' })
            });

            if (res.ok) {
                const updatedData = await res.json();
                setTasks((prevTasks) =>
                    prevTasks.map(task => task._id === updatedData.task._id ? updatedData.task : task)
                );
                alert("Task completed successfully");
                setOpenModal(false);
            } else {
                console.error('Failed to update task');
            }
        } catch (error) {
            console.error('Error updating task:', error);
        }
    };
    const getStatusBgColor = (status) => {
        switch (status) {
            case 'Approved':
                return 'bg-success';
            case 'Rejected':
                return 'bg-danger';
            case 'Pending Approval':
                return 'bg-white text-danger';
            case 'Pending':
                return 'bg-warning';
            default:
                return 'bg-secondary';
        }
    };
    return (
        <div className="container mt-4">
            <div className="d-flex justify-content-between mb-3">
                <TaskFilter
                    filters={filters}
                    setFilters={setFilters}
                    resetFilters={resetFilters}
                />
            </div>

            <div className="row">
                {tasks.length > 0 ? (
                    tasks.map((task) => (
                        <div key={task._id} className="col-md-4 mb-3">
                            
                            <div
                                className="card h-100 shadow-sm"
                                onClick={() => handleTaskClick(task)}
                                style={{ cursor: 'pointer', backgroundColor: '#e7f3ff', borderColor: task?.status === 'Pending Approval' ? '#ffc107' : '' }}
                            >
                                
                                <div className="card-body">
                                    <h5 className="card-title">{task.taskName}</h5>
                                    <p className="card-text"><strong>Status:</strong> <span className={`badge ${getStatusBgColor(task.status)}`}>{task.status}</span></p>
                                    <p className="card-text"><strong>Comment:</strong> {task.comment || 'N/A'}</p>
                                    <p className="card-text"><strong>Created:</strong> {moment(task.createdAt).format('LLL')}</p>
                                </div>
                            </div>
                        </div>
                    ))
                ) : (
                    <p>No tasks available</p>
                )}
            </div>

            {selectedTask && (
                <Modal show={openModal} onHide={() => setOpenModal(false)} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Update Task: {selectedTask.taskName}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group controlId="formPicture" className="d-flex flex-column mb-3">
                                <Form.Label>Uploaded Picture</Form.Label>
                                {selectedTask.picture && (
                                    <img src={'/images/' + selectedTask.picture} alt={selectedTask.picture} className="img-fluid mb-3" />
                                )}
                                <Form.Control
                                    type="file"
                                    onChange={(e) => handleFileChange(e.target.files)}
                                />
                            </Form.Group>
                            <Form.Group controlId="formLocation" className="mb-3">
                                <Form.Label>Location</Form.Label>
                                <Form.Check
                                    type="radio"
                                    label="Enter manually"
                                    checked={!useAutomaticLocation}
                                    onChange={() => setUseAutomaticLocation(false)}
                                />
                                <Form.Control
                                    type="text"
                                    name="location"
                                    placeholder="Enter your location here"
                                    value={formData.location}
                                    onChange={(e) => handleInputChange('location', e.target.value)}
                                    disabled={useAutomaticLocation}
                                />
                                <Form.Check
                                    type="radio"
                                    label="Get my location"
                                    checked={useAutomaticLocation}
                                    onChange={() => setUseAutomaticLocation(true)}
                                />
                                {useAutomaticLocation && (
                                    <Button variant="primary" onClick={handleLocationChange} className="mt-2">
                                        Fetch Location
                                    </Button>
                                )}
                            </Form.Group>
                            <Form.Group controlId="formComment" className="mb-3">
                                <Form.Label>Comment</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    name="comment"
                                    rows={3}
                                    placeholder="Leave a comment"
                                    value={formData.comment}
                                    onChange={(e) => handleInputChange('comment', e.target.value)}
                                />
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setOpenModal(false)}>
                            Close
                        </Button>
                        <Button
                            variant={formData.status === 'Completed' ? 'success' : 'primary'}
                            onClick={handleSubmit}
                            disabled={selectedTask.status === 'Completed' || selectedTask.status === 'Pending Approval' || selectedTask.status === 'Rejected'}
                        >
                            {formData.status === 'Completed' ? 'Completed' : 'Submit'}
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}
        </div>
    );
}

export default MyTasks;
